<template>
    <div class="bg">
        <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" ref="svg"></svg>
    </div>
</template>

<script>
    /* Derived from https://codepen.io/Yusifalomeri/pen/PabqGO */
    export default {
        data() {
			return {
				refreshDuration: 10000,
				refreshTimeout: 0,
                width: 0,
                height: 0,
				numPointsX: 0,
				numPointsY: 0,
				unitWidth: 0,
				unitHeight: 0,
				points: []
			}
		},
        mounted() {
            this.init();
			document.addEventListener("resize", this.onResize);
        },
        methods: {
            init: function() {
                this.width = window.outerWidth;
                this.height = window.outerHeight;
                this.$refs.svg.innerHTML = "";

				let unitSize = (window.innerWidth+window.innerHeight)/15;
				this.numPointsX = Math.ceil(window.innerWidth/unitSize)+1;
				this.numPointsY = Math.ceil(window.innerHeight/unitSize)+1;
				this.unitWidth = Math.ceil(window.innerWidth/(this.numPointsX-1));
				this.unitHeight = Math.ceil(window.innerHeight/(this.numPointsY-1));
				this.points = [];

				for(let y = 0; y < this.numPointsY; y++) {
					for(let x = 0; x < this.numPointsX; x++) {
						this.points.push({x:this.unitWidth*x, y:this.unitHeight*y, originX:this.unitWidth*x, originY:this.unitHeight*y});
					}
				}

				this.randomize();

				for(let i = 0; i < this.points.length; i++) {
					if(this.points[i].originX != this.unitWidth*(this.numPointsX-1) && this.points[i].originY != this.unitHeight*(this.numPointsY-1)) {
						let topLeftX = this.points[i].x;
						let topLeftY = this.points[i].y;
						let topRightX = this.points[i+1].x;
						let topRightY = this.points[i+1].y;
						let bottomLeftX = this.points[i+this.numPointsX].x;
						let bottomLeftY = this.points[i+this.numPointsX].y;
						let bottomRightX = this.points[i+this.numPointsX+1].x;
						let bottomRightY = this.points[i+this.numPointsX+1].y;

						let rando = Math.floor(Math.random()*2);

						for(let n = 0; n < 2; n++) {
							let polygon = document.createElementNS(this.$refs.svg.namespaceURI, 'polygon');

							if(rando==0) {
								if(n==0) {
									polygon.point1 = i;
									polygon.point2 = i+this.numPointsX;
									polygon.point3 = i+this.numPointsX+1;
									polygon.setAttribute('points',topLeftX+','+topLeftY+' '+bottomLeftX+','+bottomLeftY+' '+bottomRightX+','+bottomRightY);
								} else if(n==1) {
									polygon.point1 = i;
									polygon.point2 = i+1;
									polygon.point3 = i+this.numPointsX+1;
									polygon.setAttribute('points',topLeftX+','+topLeftY+' '+topRightX+','+topRightY+' '+bottomRightX+','+bottomRightY);
								}
							} else if(rando==1) {
								if(n==0) {
									polygon.point1 = i;
									polygon.point2 = i+this.numPointsX;
									polygon.point3 = i+1;
									polygon.setAttribute('points',topLeftX+','+topLeftY+' '+bottomLeftX+','+bottomLeftY+' '+topRightX+','+topRightY);
								} else if(n==1) {
									polygon.point1 = i+this.numPointsX;
									polygon.point2 = i+1;
									polygon.point3 = i+this.numPointsX+1;
									polygon.setAttribute('points',bottomLeftX+','+bottomLeftY+' '+topRightX+','+topRightY+' '+bottomRightX+','+bottomRightY);
								}
							}
							polygon.setAttribute('fill','rgba(0,0,0,'+(Math.random()/3)+')');
							let animate = document.createElementNS('http://www.w3.org/2000/svg','animate');
							animate.setAttribute('fill','freeze');
							animate.setAttribute('attributeName','points');
							animate.setAttribute('dur',this.refreshDuration+'ms');
							animate.setAttribute('calcMode','linear');
							polygon.appendChild(animate);
							this.$refs.svg.appendChild(polygon);
						}
					}
				}

				this.refresh();
			},

			randomize: function() {
				for(let i = 0; i < this.points.length; i++) {
					if(this.points[i].originX != 0 && this.points[i].originX != this.unitWidth*(this.numPointsX-1)) {
						this.points[i].x = this.points[i].originX + Math.random()*this.unitWidth-this.unitWidth/2;
					}
					if(this.points[i].originY != 0 && this.points[i].originY != this.unitWidth*(this.numPointsY-1)) {
						this.points[i].y = this.points[i].originY + Math.random()*this.unitWidth-this.unitWidth/2;
					}
				}
			},

			refresh: function() {
				this.randomize();
				for(let i = 0; i < this.$refs.svg.childNodes.length; i++) {
					let polygon = this.$refs.svg.childNodes[i];
					let animate = polygon.childNodes[0];
					if(animate.getAttribute('to')) {
						animate.setAttribute('from',animate.getAttribute('to'));
					}
					animate.setAttribute('to',this.points[polygon.point1].x+','+this.points[polygon.point1].y+' '+this.points[polygon.point2].x+','+this.points[polygon.point2].y+' '+this.points[polygon.point3].x+','+this.points[polygon.point3].y);
					animate.beginElement();
				}
				this.refreshTimeout = setTimeout(() => { this.refresh(); }, this.refreshDuration);
			},

			onResize: function() {
				clearTimeout(this.refreshTimeout);
				this.init();
			},
        }
    }
</script>

<style scoped>
    .bg {	
		position: fixed;
		background: cover;
		background: linear-gradient(145deg, #222E37 50%, #0173AF 115%);
		width: auto;
		height: auto;
		margin: 0;
		padding: 0;
		z-index: -2;
	}
</style>