<template>
	<div id="login-container">
		<div id="login-header">
			<div id="logo"></div>
			<span id="login-title">DSSA Control Panel</span>
		</div>
		<form id="login-form" method="POST" @submit.stop.prevent="attemptLogin">
			<LoginInput type="text" name="username" hint="Username" v-model="username" />
			<LoginInput type="password" name="password" hint="Password" v-model="password" />
			<input type="submit" id="login-button" title="Login" value="CONTINUE" :disabled="!formValid">
		</form>
	</div>
	<Background/>
</template>

<script>
	import Background from './components/Background.vue'
	import LoginInput from './components/LoginInput.vue'

	export default {
		name: 'App',
		components: {
			Background,
			LoginInput
		},
		data() {
			return {
				username: "",
				password: ""
			}
		},
		methods: {
			attemptLogin: function() {
				let data = `&username=${this.username}&password=${this.password}`;
				fetch('/login', {
					method: 'POST',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body: data
				})
				.then((response) => {
					if(response.ok) {
						window.location.replace("/cpanel.html");
					} else {
						alert("Invalid username or password")
					}
				})

				return false;
			}
		},
		computed: {
			formValid: function() {
				return (
					this.username.trim().length != 0
					&& this.password.trim().length != 0
				);
			}
		}
	}
</script>

<style>
	@font-face {
		font-family: 'Oxygen';
		font-style: normal;
		font-weight: 300;
		src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDcZG1Wl4LcnbuCJW8zZmW5O7w.woff2) format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	}

	@font-face {
		font-family: 'Oxygen';
		font-style: normal;
		font-weight: 300;
		src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDcZG1Wl4LcnbuCJW8zaGW5.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Oxygen';
		font-style: normal;
		font-weight: normal;
		src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDfZG1Wl4LcnbuKgE0mV0Q.woff2) format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	}

	@font-face {
		font-family: 'Oxygen';
		font-style: normal;
		font-weight: normal;
		src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDfZG1Wl4LcnbuKjk0m.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Oxygen';
		font-style: normal;
		font-weight: bold;
		src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDcZG1Wl4LcnbuCNWgzZmW5O7w.woff2) format('woff2');
		unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	}

	@font-face {
		font-family: 'Oxygen';
		font-style: normal;
		font-weight: bold;
		src: url(https://fonts.gstatic.com/s/oxygen/v10/2sDcZG1Wl4LcnbuCNWgzaGW5.woff2) format('woff2');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	html, body {
		width: 100%;
		height: 100%;
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		overflow: hidden;
		font-size: 14px;
		font-family: 'Oxygen', Gill Sans, Gill Sans MT, Calibri, sans-serif; 
	}

	#app {
		width: 100%;
		height: 100%;
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>

<style scoped>
	#login-container {
		width: 30rem;
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}

	#login-header {
		width: 100%;
		margin: 0px;
		margin-bottom: 1.0rem;
		padding: 0px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	#logo {
		padding: 0px;
		margin: 0px;
		margin-right: 1.0rem;
		width: 6rem;
		height: 6rem;
		background-image: url('../assets/logo.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	#login-title {
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		color: rgb(230, 230, 230);
		font-size: 1.4rem;
		font-weight: bold;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	#login-form {
		margin: 0px;
		padding: 0px;
		padding: 2.0rem;
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 0.08);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	#login-button {
		width: 100%;
		margin: 0px;
		margin-top: 1.5rem;
		padding: 1.0rem;
		font-size: 1.0rem;
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 0.3);
		border-color: rgba(255, 255, 255, 0.1);
		color: rgba(255, 255, 255, 1.0);
		border: none;
		outline: none;
		font-weight: 300;
		letter-spacing: 1px;
	}

	#login-button:not(:disabled):hover {
		cursor: pointer;
		transition: all 0.3s ease;
		background-color: rgba(255, 255, 255, 0.4);
	}

	#login-button:disabled {
		background-color: rgba(255, 255, 255, 0.1);
		color: rgba(255, 255, 255, 0.6);
	}
</style>
