<template>
	<span>
		<input ref="input" :type="type" :name="name" :value="modelValue" @input="onInput" @focus="focused = true" @blur="focused = false">
		<label :moved="moveLabel">{{hint}}</label>
	</span>
</template>

<script>
	export default {
		data() {
			return {
				focused: false
			}
		},
		props: ['type', 'name', 'hint', 'modelValue'],
		methods: {
			onInput: function() {
				this.$emit("update:modelValue", this.$refs.input.value);
			}
		},
		computed: {
			moveLabel: function() {
				return (
					this.focused
					|| this.modelValue.length != 0
				);
			}
		}
	}
</script>

<style scoped>
	span {
		width: 100%;
		margin: 0px;
		margin-bottom: 1.5rem;
		padding: 0px;
		box-sizing: border-box;
		position: relative;
	}

	input {
		width: 100%;
		margin: 0px;
		padding: 1.3rem 1.0rem 0.7rem 1.0rem;
		font-size: 1.0rem;
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 0.75);
		color: rgb(0, 0, 0);
		border: none;
		outline: none;
	}

	input:hover {
		transition: all 0.3s ease;
		background-color: rgba(255, 255, 255, 1.0);
	}

	input:focus {
		background-color: rgba(255, 255, 255, 1.0);
	}

	label {
		transition: all 0.15s ease;
		margin: 0px;
		padding: 0px;
		position: absolute;
		z-index: 1;
		top: 1.0rem;
		left: 1.0rem;
		font-size: 1.0rem;
		pointer-events: none;
		color: rgb(100, 100, 100);
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	label[moved=true] {
		transition: all 0.15s ease;
		top: 2px;
		left: 3px;
		font-size: 0.7rem;
	}
</style>